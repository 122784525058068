<template>
  <div class="main">
    <div class="page-box">
      <div class="page-title">签证状态跟踪(赴沙)管理</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="breadcrumb-active"
          ><i class="fa fa-dashboard"></i>工作台</el-breadcrumb-item
        >
        <el-breadcrumb-item class="breadcrumb-active">
          签证状态跟踪(赴沙)管理</el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>
    <div class="card-box">
      <div class="screen-box">
        <div class="screen-input-box">
          <div class="title-box">项目部:</div>
          <el-select
            v-model="projectID"
            placeholder="请选择项目部"
            filterable
            clearable
            class="card-select"
          >
            <el-option
              v-for="item in projectList"
              :key="item.ProjectID"
              :label="item.ProjectName"
              :value="item.ProjectID"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-input-box">
          <div class="title-box">用工单位:</div>
          <el-select
            v-model="companyID"
            placeholder="请选择用工单位"
            filterable
            clearable
            class="card-select"
          >
            <el-option
              v-for="item in companyList"
              :key="item.ID"
              :label="item.CompanyCHS"
              :value="item.ID"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-input-box">
          <div class="title-box">签证是否完成:</div>
          <el-select
            v-model="visaCompletionStauts"
            placeholder="签证是否完成"
            filterable
            clearable
            class="card-select"
          >
            <el-option
              v-for="item in options1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-input-box">
          <div class="title-box">护照号:</div>
          <el-input v-model="ppCode" clearable placeholder="请输入护照号" />
        </div>
        <div class="screen-input-box">
          <div class="title-box">申请日期:</div>
          <el-date-picker
            v-model="evacuationDate1"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
          >
          </el-date-picker>
        </div>
        <div class="screen-input-box">
          <div class="title-box">签证有效期:</div>
          <el-date-picker
            v-model="evacuationDate2"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
          >
          </el-date-picker>
        </div>
        <button class="btn-pre" @click="getRecordforVisas(1)">
          <i class="fa fa-search"></i>查询
        </button>
        <button class="btn-pre" @click="exportRecordforVisas">
          <i class="fa fa-download"></i>导出
        </button>
      </div>
      <div class="table-box">
        <el-table
          :data="tableData"
          height="500"
          border
          tooltip-effect="light"
          :header-cell-style="{ color: '#f5f5f5', background: '#23527c' }"
          :empty-text="loading ? '加载中...' : '暂无数据'"
          ref="myTable"
        >
          <el-table-column label="#" width="55">
            <template slot-scope="scope">
              <el-checkbox></el-checkbox>
            </template>
          </el-table-column>

          <el-table-column type="index" label="序号" width="50">
          </el-table-column>
          <el-table-column prop="EmployeeCode" label="员工编码" width="75" />
          <el-table-column prop="NameinEnglish" label="拼音姓名" width="75" />
          <el-table-column prop="Gender" label="性别" width="50">
            <template slot-scope="scope">
              {{ scope.row.Gender === 1 ? "男" : "女" }}
            </template>
          </el-table-column>
          <el-table-column prop="NationalityName" label="国籍" width="50" />
          <el-table-column prop="DateofBirth" label="出生日期" width="80">
            <template slot-scope="scope">
              {{ scope.row.DateofBirth | tableDateFrom }}
            </template>
          </el-table-column>
          <el-table-column prop="Age" label="年龄" width="50" />
          <el-table-column prop="ReligionName" label="宗教信仰" width="75" />
          <el-table-column prop="ProjectName" label="项目名称" width="75" />
          <el-table-column
            prop="SubProjectName"
            label="分项目名称"
            width="90"
          />
          <el-table-column prop="WorkingUnit" label="用工单位" width="75" />
          <el-table-column
            prop="JobProfessionName"
            label="岗位名称"
            width="75"
          />
          <el-table-column prop="PassportCode" label="护照号" width="75" />
          <el-table-column
            prop="PassportValidDate"
            label="护照有效期"
            width="90"
          >
            <template slot-scope="scope">
              {{ scope.row.PassportValidDate | tableDateFrom }}
            </template>
          </el-table-column>
          <el-table-column prop="ApplicationDate" label="申请日期" width="80">
            <template slot-scope="scope">
              {{ scope.row.ApplicationDate | tableDateFrom }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="120">
            <template slot-scope="scope">
              <i class="fa fa-edit" @click="edit(scope.row)"></i>
              <i
                class="fa fa-image"
                @click="image(scope.row)"
                v-if="powers.upload"
              ></i>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          @size-change="getRecordforVisas(1)"
          @current-change="getRecordforVisas"
          :current-page.sync="page"
          :page-size.sync="size"
          :page-sizes="[10, 20, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog
      title="附件上传"
      :visible.sync="dialogVisible"
      width="600px"
      :before-close="handleClose"
      top="50px"
      class="dialog"
    >
      <el-table
        :data="tableData2"
        border
        class="tableData2"
        tooltip-effect="light"
        empty-text="暂无附件"
        :header-cell-style="{
          color: '#333',
          background: '#f9f9f9',
          'font-weight': 'bolder',
        }"
      >
        <el-table-column type="index" label="序号" width="40" />
        <el-table-column prop="Name" label="文件名称" width="150" />
        <el-table-column label="操作" width="80">
          <template slot-scope="scope">
            <i
              class="fa fa-cloud-download"
              @click="download(scope.row.Url)"
            ></i>
            <i class="fa fa-trash" @click="deleteTrash(scope)"></i>
          </template>
        </el-table-column>
      </el-table>

      <div class="upload-box">
        <div class="left">
          <div v-if="name" class="name">
            <svg-icon class="icon" icon-class="glyphicon-file"></svg-icon>
            {{ name }}
          </div>
        </div>
        <el-upload
          action="#"
          class="card-upload"
          :auto-upload="true"
          :http-request="uploadAttachment"
          ref="uploadRef"
        >
          <button
            :disabled="tableData2.length >= 5"
            :class="['btn-up', { disabled: tableData2.length >= 5 }]"
          >
            <svg-icon class="icon" icon-class="file"></svg-icon>选择 ...
          </button>
        </el-upload>
      </div>
      <div class="dialog-footer">
        <el-button class="pop-close" @click="handleClose">关闭</el-button>
        <el-button class="pop-save pop-right" @click="addAttachment"
          >保存</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="编辑签证状态跟踪(赴沙)管理"
      :visible.sync="dialogVisible1"
      width="1200px"
      :before-close="handleClose1"
      top="3vh"
    >
      <div class="dialog-main">
        <div class="line-box">
          <div class="from-box">
            <div class="name">护照复印件:</div>
            <el-select
              v-model="params1.PassportCollection"
              placeholder="请选择"
              filterable
              clearable
              class="card-select"
            >
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="from-box">
            <div class="name">身份证复印件:</div>
            <el-select
              v-model="params1.HomeCountryIDCard"
              placeholder="请选择"
              filterable
              clearable
              class="card-select"
            >
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="from-box">
            <div class="name">国际旅行健康检查证明书:</div>
            <el-select
              v-model="params1.HomeCountryMedicalReport"
              placeholder="请选择"
              filterable
              clearable
              class="card-select"
            >
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="line-box">
          <div class="from-box">
            <div class="name">疫苗接种国际证书:</div>
            <el-select
              v-model="params1.Vaccination"
              placeholder="请选择"
              filterable
              clearable
              class="card-select"
            >
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="from-box">
            <div class="name">招聘代理资料:</div>
            <el-select
              v-model="params1.RecruitmentAgencyDocument"
              placeholder="请选择"
              filterable
              clearable
              class="card-select"
            >
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="from-box">
            <div class="name">无犯罪记录国内双认证:</div>
            <el-select
              v-model="params1.DocumentsAttestedinHomeCountry"
              placeholder="请选择"
              filterable
              clearable
              class="card-select"
            >
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="line-box">
          <div class="from-box">
            <div class="name">员工健康评估:</div>
            <el-select
              v-model="params1.EmployeeHealthAssessment"
              placeholder="请选择"
              filterable
              clearable
              class="card-select"
            >
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="from-box">
            <div class="name">出国任务批件:</div>
            <el-select
              v-model="params1.OverseasMissionDocument"
              placeholder="请选择"
              filterable
              clearable
              class="card-select"
            >
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="from-box">
            <div class="name">境外公共安全培训:</div>
            <el-select
              v-model="params1.PublicSafetyTraining"
              placeholder="请选择"
              filterable
              clearable
              class="card-select"
            >
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="line-box">
          <div class="from-box">
            <div class="name">邀请函:</div>
            <el-select
              v-model="params1.ValidDateofLetter"
              placeholder="请选择"
              filterable
              clearable
              class="card-select"
            >
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="from-box">
            <div class="name">签证是否完成:</div>
            <el-select
              v-model="params1.VisaComplettionStauts"
              placeholder="请选择"
              filterable
              clearable
              class="card-select"
            >
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="from-box">
            <div class="name">签证完成时间:</div>
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="params1.VisaCompletionDate"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="line-box">
          <div class="from-box">
            <div class="name">签证号:</div>
            <el-input v-model="params1.VisaNO" placeholder="请输入" />
          </div>
          <div class="from-box">
            <div class="name">签证有效期:</div>
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="params1.ValidDatetoMobilization"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </div>
          <div class="from-box">
            <div class="name">是否入沙:</div>
            <el-select
              v-model="params1.WhetherEntertheKSA"
              placeholder="请选择"
              filterable
              clearable
              class="card-select"
            >
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="line-box">
          <div class="from-box2">
            <div class="name">入沙时间:</div>
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="params1.DateEntertheKSA"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </div>
          <div class="from-box2">
            <div class="name">备注:</div>
            <el-input v-model="params1.Remark" placeholder="请输入" />
          </div>
        </div>
      </div>
      <div class="dialog-footer">
        <el-button class="pop-close" @click="handleClose1">取消</el-button
        ><el-button class="pop-save" @click="save(1)">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getRecordforVisas,
  exportRecordforVisas,
  getRecordforVisasItem,
  updateRecordforVisa,
} from "@/api/table";
import {
  getProjectAll,
  getCompanyAll,
  allAttachment,
  uploadAttachment,
  deleteAttachment,
  addAttachment,
} from "@/api/user";
import ForeignAffairsBusiness from "@/components/fTable/ForeignAffairsBusiness.vue";
export default {
  filters: {
    companyFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.CompanyCHS : "";
    },
    projectFormater(id, data) {
      const item = data.find((item) => {
        return item.ProjectID === id;
      });
      return item ? item.ProjectName : "";
    },
    tableDateFrom(data) {
      if (data) {
        data = data.slice(0, 10);
      }
      return data;
    },
    leablDateFrom(id, data) {
      const item = data.find((item) => {
        return item.value === id;
      });
      return item ? item.label : "";
    },
  },
  data() {
    return {
      tableData: [],
      tableData2: [],
      multipleSelection: [],
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      page: 1,
      size: 10,
      total: 10,
      powers: {
        add: false,
        update: false,
        delete: false,
        download: false,
        import: false,
        export: false,
        print: false,
        examine: false,
        upload: false,
        resetting: false,
      },
      loading: true,
      visaCompletionStauts: null,
      projectID: null,
      companyID: null,
      ppCode: null,
      evacuationDate1: null,
      evacuationDate2: null,
      projectList: [],
      companyList: [],
      options1: [
        {
          value: 1,
          label: "Pending",
        },
        {
          value: 2,
          label: "Done",
        },
      ],
      name: null,

      params1: {
        ID: null,
        KeyID: null,
        PassportCollection: null,
        PassportCollectionDate: null,
        HomeCountryIDCard: null,
        HomeCountryMedicalReport: null,
        Vaccination: null,
        RecruitmentAgencyDocument: null,
        DocumentsAttestedinHomeCountry: null,
        EmployeeHealthAssessment: null,
        OverseasMissionDocument: null,
        PublicSafetyTraining: null,
        ValidDateofLetter: null,
        VisaComplettionStauts: null,
        VisaCompletionDate: null,
        VisaNO: null,
        ValidDatetoMobilization: null,
        WhetherEntertheKSA: null,
        DateEntertheKSA: null,
        Remark: null,
      },
    };
  },
  components: { ForeignAffairsBusiness },
  methods: {
    getButtonPower() {
      if (!this.$store.state.userPower) {
        this.$store.commit(
          "changeUserPower",
          JSON.parse(localStorage.getItem("userPower"))
        );
      }

      const item = this.$store.state.userPower.find((item) => {
        return item.Name === this.$route.meta.title;
      });
      const actions = item.Actions;

      actions.forEach((element) => {
        switch (element.Id) {
          case 1: //增加
            this.powers.add = true;
            break;
          case 2: //删除
            this.powers.delete = true;
            break;
          case 3: //修改
            this.powers.update = true;
            break;
          case 5: //下载
            this.powers.download = true;
            break;
          case 6: //导入
            this.powers.import = true;
            break;
          case 7: //导出
            this.powers.export = true;
            break;
          case 8: //打印
            this.powers.print = true;
            break;
          case 9: //审批
            this.powers.examine = true;
            break;
          case 10: //上传
            this.powers.upload = true;
            break;
          case 11: //重置
            this.powers.resetting = true;
            break;
        }
      });
    },
    //获取表格
    getRecordforVisas(type) {
      if (type) {
        this.page = type;
      }
      this.loading = true;
      let startDate = null;
      let endDate = null;
      let validStartDate = null;
      let validEndDate = null;

      if (this.evacuationDate1) {
        startDate = this.evacuationDate1[0];
        endDate = this.evacuationDate1[1];
      }

      if (this.evacuationDate2) {
        validStartDate = this.evacuationDate2[0];
        validEndDate = this.evacuationDate2[1];
      }
      const data = {
        page: this.page,
        size: this.size,
        visaCompletionStauts: this.visaCompletionStauts,
        projectID: this.projectID,
        companyID: this.companyID,
        ppCode: this.ppCode,
        startDate,
        endDate,
        validStartDate,
        validEndDate,
      };
      getRecordforVisas(data).then((res) => {
        if (res.status === 200) {
          this.tableData = res.response.data;
          this.total = res.response.dataCount;
        }
        this.loading = false;
      });
    },
    //导出
    exportRecordforVisas() {
      let startDate = null;
      let endDate = null;
      let validStartDate = null;
      let validEndDate = null;

      if (this.evacuationDate1) {
        startDate = this.evacuationDate1[0];
        endDate = this.evacuationDate1[1];
      }

      if (this.evacuationDate2) {
        validStartDate = this.evacuationDate2[0];
        validEndDate = this.evacuationDate2[1];
      }
      const data = {
        page: this.page,
        size: this.size,
        visaCompletionStauts: this.visaCompletionStauts,
        projectID: this.projectID,
        companyID: this.companyID,
        ppCode: this.ppCode,
        startDate,
        endDate,
        validStartDate,
        validEndDate,
      };
      exportRecordforVisas(data).then((res) => {
        if (res.status === 200) {
          let url = res.response;
          let charIndex = url.indexOf("Download");
          url = url.substr(charIndex, url.length);
          url = url.replace(/\\/g, "/");
          location.href = "https://hrms.baishulai.com/" + url;
        }
      });
    },

    handleClose() {
      this.dialogVisible = false;
      this.name = null;
    },
    handleClose1() {
      this.dialogVisible1 = false;
      this.params1 = {
        ID: null,
        KeyID: null,
        PassportCollection: null,
        PassportCollectionDate: null,
        HomeCountryIDCard: null,
        HomeCountryMedicalReport: null,
        Vaccination: null,
        RecruitmentAgencyDocument: null,
        DocumentsAttestedinHomeCountry: null,
        EmployeeHealthAssessment: null,
        OverseasMissionDocument: null,
        PublicSafetyTraining: null,
        ValidDateofLetter: null,
        VisaComplettionStauts: null,
        VisaCompletionDate: null,
        VisaNO: null,
        ValidDatetoMobilization: null,
        WhetherEntertheKSA: null,
        DateEntertheKSA: null,
        Remark: null,
      };
    },

    allAttachment() {
      allAttachment({
        workTable: "RecordforVisa",
        workTableKey: this.KeyID,
      }).then((res) => {
        if (res.status === 200) {
          this.tableData2 = res.response ? res.response : [];
        }
      });
    },
    uploadAttachment(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      if (!suffix && !suffix2 && !suffix3 && !suffix4 && !suffix5 && !suffix6) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx的文件!"
        );
        return;
      }

      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          let FileType = 1;
          if (suffix || suffix2 || suffix3) {
            FileType = 2;
          }
          const item = {
            Name: data.name,
            Url: res.response,
            FileType,
          };
          this.name = data.name;
          this.tableData2.push(item);
        }
      });
    },
    image(data) {
      this.dialogVisible = true;
      this.KeyID = data.KeyID;
      this.FormID = data.ID;
      this.allAttachment();
    },
    deleteTrash(data) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        if (data.row.ID) {
          deleteAttachment({ id: data.row.ID }).then((res) => {
            if (res.status === 200) {
              this.$message.success("删除成功");
              this.tableData2.splice(data.$index, 1);
            }
          });
        } else {
          this.$message.success("删除成功");
          this.tableData2.splice(data.$index, 1);
        }
      });
    },
    addAttachment() {
      const data = {
        WorkTable: "RecordforVisa",
        WorkTableKey: this.KeyID,
        FormID: this.FormID,
        ModuleID: 337,
        Details: [],
      };

      this.tableData2.forEach((item, index) => {
        const detailItem = {
          ID: 0,
          Name: item.Name,
          Url: item.Url,
          FileType: item.FileType,
          SortID: index + 1,
        };
        if (item.ID) {
          detailItem.ID = item.ID;
        }
        data.Details.push(detailItem);
      });
      addAttachment(data).then((res) => {
        if (res.status === 200) {
          this.$message.success("保存成功");
          this.handleClose();
        }
      });
    },
    //下载附件
    download(data) {
      location.href = process.env.VUE_APP_SERVER + "upload/" + data;
    },
    async edit(data1) {
      let data = {};
      await getRecordforVisasItem({ key: data1.KeyID }).then((res) => {
        if (res.status === 200) {
          data = res.response;
        }
      });
      this.params1 = {
        ID: data.ID,
        KeyID: data.KeyID,
        PassportCollection: data.PassportCollection,
        PassportCollectionDate: null,
        HomeCountryIDCard: data.HomeCountryIDCard,
        HomeCountryMedicalReport: data.HomeCountryMedicalReport,
        Vaccination: data.Vaccination,
        RecruitmentAgencyDocument: data.RecruitmentAgencyDocument,
        DocumentsAttestedinHomeCountry: data.DocumentsAttestedinHomeCountry,
        EmployeeHealthAssessment: data.EmployeeHealthAssessment,
        OverseasMissionDocument: data.OverseasMissionDocument,
        PublicSafetyTraining: data.PublicSafetyTraining,
        ValidDateofLetter: data.ValidDateofLetter,
        VisaComplettionStauts: data.VisaComplettionStauts,
        VisaCompletionDate: data.VisaCompletionDate,
        VisaNO: data.VisaNO,
        ValidDatetoMobilization: data.ValidDatetoMobilization,
        WhetherEntertheKSA: data.WhetherEntertheKSA,
        DateEntertheKSA: data.DateEntertheKSA,
        Remark: data.Remark,
      };
      this.dialogVisible1 = true;
    },
    save() {
      updateRecordforVisa(this.params1).then((res) => {
        if (res.status === 200) {
          this.$message.success("编辑成功");
          this.handleClose1();
          this.getRecordforVisas();
        }
      });
    },
  },

  created() {
    this.getButtonPower();
    this.getRecordforVisas();

    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
      }
    });
    getCompanyAll().then((res) => {
      if (res.status === 200) {
        this.companyList = res.response;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;
  background-color: #f7f8fa;
  .page-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .page-title {
      font-size: 18px;
      color: #000000;
    }
    .breadcrumb-active {
      color: #303133;
      font-weight: bolder;
      cursor: pointer;
      .fa {
        margin-right: 5px;
      }
    }
  }
  .card-box {
    width: 100%;
    // min-height: calc(100% - 48px);
    // height: calc(100% - 48px);
    background: #ffffff;
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
    margin-top: 24px;
    box-sizing: border-box;
    padding: 0 0 24px 0;
    .screen-box {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      position: relative;
      padding: 0 10px;
      .screen-input-box {
        margin-top: 24px;
      }
      .btn-pre {
        margin-top: 24px;
      }
      .btn-add {
        margin-top: 24px;
      }
      .el-input {
        width: 150px;
        margin-right: 24px;
      }
      .el-button {
        margin-right: 24px;
      }
      .el-select {
        width: 150px;
        margin-right: 24px;
      }
      .el-date-editor {
        margin-right: 24px;
      }
      .senior {
        position: absolute;
        width: 100%;
        top: 40px;
        left: 0;
        background: #fefefe;
        border: 1px solid #eae8e8;
        border-top-width: 1px;
        border-top-style: solid;
        border-top-color: rgb(234, 232, 232);
        box-sizing: border-box;
        z-index: 999;
        padding: 0 24px 24px 24px;
        border-top: 0;
        box-shadow: 0 7px 18px -12px #bdc0bb;
        .from-boxs {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .from-box {
            margin-top: 24px;
            .name {
              margin-bottom: 10px;
            }
            .el-select {
              width: 150px;
            }
            .el-input {
              width: 150px;
            }
          }
        }
        .buttons {
          display: flex;
          justify-content: flex-end;
          margin-top: 24px;
        }
      }
    }
    .table-box {
      width: 100%;
      // height: calc(100% - 70px);
      .el-table {
        margin-top: 24px;
        // height: calc(100% - 56px);
        min-height: 500px;
        box-sizing: border-box;
      }
      .el-pagination {
        margin-top: 24px;
      }
      .table-color {
        color: #0076ff;
      }
      .buttons {
        display: flex;
      }
      .fa {
        margin-right: 10px;
        color: #3c8dbc;
        cursor: pointer;
      }
      .fa:hover {
        color: #72afd2;
      }
    }
  }
  .dialog-main {
    border-bottom: 1px solid #f4f4f4;
    padding: 12px 24px 0 24px;
    width: 100%;
    box-sizing: border-box;
    .line-box {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .from-box {
        display: flex;
        align-items: center;
        width: 32%;
        margin-bottom: 24px;
        .name {
          width: 120px;
          font-size: 16px;
          margin-right: 10px;
          flex-shrink: 0;
          text-align: right;
        }
        .el-select {
          width: 100%;
        }
        .el-date-editor {
          width: 100%;
        }
      }
      .from-box2 {
        display: flex;
        align-items: center;
        width: 50%;
        margin-bottom: 24px;
        .name {
          width: 120px;
          margin-right: 10px;
          flex-shrink: 0;
          text-align: right;
        }
        .el-input {
          width: 100%;
        }
        .el-date-editor {
          width: 100%;
        }
      }
    }
  }
  .dialog-footer {
    height: 60px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .el-button {
      height: 34px;
      padding: 0 20px;
      margin-right: 12px;
      margin-left: 0;
    }
  }
}
</style>
<style lang="scss" scoped>
::v-deep .el-table {
  width: 100%;
  .el-table__header-wrapper table,
  .el-table__body-wrapper table {
    width: 100% !important;
  }
  .el-table__body,
  .el-table__footer,
  .el-table__header {
    table-layout: auto;
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
  .el-table__header {
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
}
.upload-box {
  display: flex;
  height: 34px;
  margin-top: 24px;
  .left {
    width: 100%;
    border: 1px solid #d2d6de;
    border-right: none;
    .name {
      display: flex;
      align-items: center;
      height: 34px;
      color: #555;
      font-size: 14px;
      text-indent: 2px;
    }
  }
  .btn-up {
    padding: 0;
    margin: 0;
    border: none;
    width: 90px;
    flex-shrink: 0;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #3c8dbc;
    border: 1px solid #367fa9;
    color: #fff;
    font-size: 14px;
    .icon {
      margin-right: 5px;
    }
  }
  .disabled {
    cursor: no-drop;
  }
  .btn-up:hover {
    border-color: #204d74;
    background-color: #367fa9;
  }
}
</style>
<style scoped>
.el-dialog__wrapper >>> .el-dialog__body {
  padding: 0;
  overflow: auto;
  border-top: 1px solid #f4f4f4;
}
.el-dialog__wrapper >>> .my-label {
  background: #f5f7fa !important;
}
.el-textarea >>> .el-textarea__inner {
  min-height: 100px !important;
}
.el-table >>> .el-table__placeholder {
  width: 23px;
}
.table-box >>> .el-table__empty-block {
  width: 100% !important;
}
.el-table >>> th.el-table__cell > .cell {
  font-weight: bolder;
  font-size: 12px;
  text-align: center;
  font-family: "Microsoft YaHei";
}

.el-table >>> th.el-table__cell {
  padding: 6px 0;
}

.el-table >>> .el-table__body .cell {
  text-align: center;
  font-size: 12px;
  color: #333;
}
.el-table >>> .el-table__cell {
  padding: 6px 0;
}
.approval {
  display: inline;
  padding: 2px 5px;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  font-size: 12px;
}
.label-success {
  background-color: #00a65a;
}
.label-warning {
  background-color: #f39c12;
}
.fa {
  margin-right: 10px;
  color: #3c8dbc;
  cursor: pointer;
}
.fa:hover {
  color: #72afd2;
}
.screen-box >>> .el-input__inner {
  height: 34px;
  border-color: #d2d6de;
  border-radius: 0;
}
.screen-box >>> .el-input__inner:focus {
  height: 34px;
  border-color: #61a2c9;
  border-radius: 0;
}
.card-upload >>> .el-upload-list {
  display: none;
}
.dialog >>> .el-dialog__body {
  padding: 15px 15px 0 15px;
}
.tableData2 >>> .el-table__empty-block {
  width: 100% !important;
}
.card-select >>> .el-input__icon {
  line-height: 34px;
}
</style>
